import { Environment } from 'goodmaps-sdk';

const config = {
  NAME: 'Staging',
  TRACKING_ID: '',
  ENV_ID: Environment.Stage,
  userPoolId: 'us-east-2_3NepvH2Tl',
  userPoolWebClientId: '29u3n8nhg2knhdic92hua9ebl8',
  immersalCpsEndpoint: 'https://stage.api.goodmaps.io/servicesv2-services/cpsi',
  immersalAzureEndpoint: 'https://services.goodmapsaz.io/cpsi',
  region: 'us-east-2',
  V1_API: 'https://stage.api.goodmaps.io/servicesv2-services',
  AZURE_API: 'https://services.goodmapsaz.io',
  AWS_API: 'https://services.goodmaps.io',
  schemaVersion: '1',
};

export default config;
