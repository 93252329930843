import * as THREE from 'three';

type int = number;

export class GroundTruthMethodState {

    // centroids by position
    readonly centroids = new Map<int, THREE.Vector2>(); 

    // number of locations included in centroid calculation, by position
    readonly numLocationsInCentroid = new Map<int, int>(); 
    
    // CPS result distances from centroid, by position and orientation
    readonly distances = new Map<int, Map<int, number>>(); 
    
    // avg distances by position
    readonly meanDistances = new Map<int, Number>(); 
    
    // standard deviation of distances, by position
    readonly stdDevDistances = new Map<int, number>(); 
    
    // [RMSE-x, RMSE-y, RMSE-combined] by position
    readonly rmseDistances = new Map<int, [number, number, number]>(); 
    
    // orientations within threshold, by position
    readonly numCloseEnough = new Map<int, int>(); 

    overallNumCloseEnough: int = 0;
    
    overallCorrectRate: number = 0.0;
    
    overallMeanDistance: number = 0.0;
    
    overallStdDev: number = 0.0;
    
    overallRmseX: number = 0.0;
    
    overallRmseY: number = 0.0;
    
    overallRmse: number = 0.0;
    
  /**
   * Checks results against each other for internal consistency.  A faliure could
   * indicate a bug or edge case that needs to be handled differently.
   */
  checkConsistency(): boolean {
    this.numLocationsInCentroid.forEach( (count, posId) => {
      if (count < 0) {
          console.error("Internal consistency of method results failed: numLocationsInCentroid cannot be negative");
          return false;
        }
    });

    return true;
  }

  getOverallNumIncludedInCentroids() {
    let count = 0;
    this.numLocationsInCentroid.forEach( (numLoc) => { count += numLoc; });
    return count;
  }

}
