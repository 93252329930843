import NanoTimer from 'nanotimer';
import * as THREE from 'three';
import * as gm from 'goodmaps-sdk';
import * as analytics from 'goodmaps-sdk/dist/analytics';
import { ExploreBuilding } from '_sdks/cavu-sdk/src';
import { trackPromise } from 'react-promise-tracker';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import config from 'global/config';
import {
  DataLoggerDataset,
  DataLoggerDatasetList,
  DataLoggerMetadata,
  VideoData,
} from 'goodmaps-utils';
import {
  getAWSDataLoggerFiles,
  getAzureDataLoggerFile,
  getAzureDataLoggerFiles,
  getAWSDataLoggerFile,
} from 'helpers/ApolloHelper';
import HealthCheckResults from 'helpers/HealthCheckResults';
import { computeCentroid, computeMetrics } from 'helpers/HealthCheckHelper';
import * as BuildingManagerHelper from '../helpers/BuildingManagerHelper';
import {
  CPS_LOADING_CHANGED,
  CPS_ERROR_CHANGED,
  AR_POSITION_UPDATED,
  DATALOGGER_FILES_LOADED,
  DATALOGGER_FILE_SELECTED,
  DATALOGGER_FILE_CLEARED,
  MAP_OVERLAY_CPS_IMAGE_UPDATED,
  MAP_OVERLAY_EXTRA_INFO_UPDATED,
  CPS_TEST_MESSAGE_UPDATE,
} from './types';


const dataNanoTimer = new NanoTimer();

let nextUpdateStep = 0;
let cpsTimestamp;
const initialDelay = 0;
let requestCounter = 0;

export interface DataLoggerMetadataExt extends DataLoggerMetadata {
  source?: 'aws' | 'az';
  type?: '1.0' | '2.0';
}

export const clearCPSDataList = () => dispatch => {
  dispatch({ type: DATALOGGER_FILE_CLEARED });
};

export const getDataLoggerData = () => {
  dataNanoTimer.clearTimeout();
  dataNanoTimer.clearInterval();
  return async (dispatch, getState) => {
    const conf: gm.BuildingManagerConfig = {
      userType: 'cavu',
      userId: 'cavu',
      jwt: getState().authenticator.jwt,
      environment: config.ENV_ID,
      language: 'en',
    };

    const aStore = analytics.createAnalyticsStore(conf);
    const status = await aStore.getList({ buildingId: 'DataLogger' });

    let dataLoggerSets: DataLoggerMetadataExt[] = (status as DataLoggerMetadataExt[]).map(d => {
      d.source = 'aws';
      d.type = '1.0';
      return d;
    });

    const azureDataLoggerSets: DataLoggerMetadataExt[] = await getAzureDataLoggerFiles();
    const awsDataLoggerSets: DataLoggerMetadataExt[] = await getAWSDataLoggerFiles();

    if (azureDataLoggerSets)
      dataLoggerSets = [...dataLoggerSets, ...azureDataLoggerSets, ...awsDataLoggerSets];

    const all = dataLoggerSets.sort(
      (a, b) => new Date(a.createdAtISO).getTime() - new Date(b.createdAtISO).getTime()
    );

    dispatch({ type: DATALOGGER_FILES_LOADED, payload: all.reverse() });
  };
};

export const selectDataLoggerFile = (data: DataLoggerMetadataExt) => async (dispatch, getState) => {
  if (data.source === 'aws' && data.type === '1.0') {
    const conf: gm.BuildingManagerConfig = {
      userType: 'cavu',
      userId: 'cavu',
      jwt: getState().authenticator.jwt,
      environment: config.ENV_ID,
      language: 'en',
    };

    const aStore = analytics.createAnalyticsStore(conf);

    const datasetMetaData = await aStore.getDataSet(data.dataSetId);
    const datasetURL = (datasetMetaData as any).url;

    const response = await fetch(datasetURL, {
      method: 'GET',
      headers: {
        ContentType: 'application/json',
      },
    });

    const responseJson = await response.json();

    let dataSet: DataLoggerDatasetList;
    if (responseJson.name) {
      // NEW format handling
      dataSet = {
        dataSetId: responseJson.dataSetId,
        description: responseJson.description,
        name: responseJson.name,
        dataset: responseJson.dataset.map(d => {
          const dt: DataLoggerDataset = { dataType: d.dataType };
          switch (d.dataType) {
            case 'meta': {
              dt.metaData = d.metadata;
              break;
            }
            case 'image': {
              dt.imageData = d.imageData;
              break;
            }
            case 'video': {
              dt.videoData = d.videoData;
              break;
            }
            default:
              break;
          }
          return dt;
        }),
      };
    } else {
      // OLD format handling
      dataSet = {
        dataSetId: '',
        description: data.description,
        name: data.name,
        dataset: responseJson.dataset.map(d => {
          const dt: DataLoggerDataset = { dataType: d.dataType };
          switch (d.dataType) {
            case 'meta': {
              dt.metaData = d.metadata;
              break;
            }
            case 'image': {
              dt.imageData = {
                data: d.data.data,
                description: d.data.description,
                name: d.data.name,
                isRecording: d.data.isRecording,
              };
              break;
            }
            case 'video': {
              dt.videoData = d.videoData;
              break;
            }
            default:
              break;
          }
          return dt;
        }),
      };
    }
    dispatch({ type: DATALOGGER_FILE_SELECTED, payload: dataSet });
  } else if (data.source === 'aws') {
    const file = await getAWSDataLoggerFile(data.dataSetId);
    dispatch({ type: DATALOGGER_FILE_SELECTED, payload: file });
  } else {
    const file = await getAzureDataLoggerFile(data.dataSetId);
    dispatch({ type: DATALOGGER_FILE_SELECTED, payload: file });
  }
};

export const deleteDataSetFromList = (datasetID: string) => async (dispatch, getState) => {
  const conf: gm.BuildingManagerConfig = {
    userType: 'cavu',
    userId: 'cavu',
    jwt: getState().authenticator.jwt,
    environment: gm.Environment.Prod,
    language: 'en',
  };

  const aStore = analytics.createAnalyticsStore(conf);
  const deleteStatus = await aStore.deleteDataSet(datasetID);
  dispatch(getDataLoggerData());
  return deleteStatus;
};

export const selectDataLoggerCPSData = (
  data: DataLoggerDataset,
  override = undefined,
  maps = undefined
) => {
  // Renderer.clearMarker('cps-arrow');
  // Renderer.clearMarker('cps-circle');
  // Renderer.clearMarker('user-circle');
  // Renderer.clearMarker('userOrientation-arrow');

  const building = BuildingManagerHelper.getCurrentExploreEntity();

  return async dispatch => {
    dispatch(stop());
    dispatch({ type: CPS_LOADING_CHANGED, payload: true });

    dispatch({
      type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
      payload: {
        cpsType: (building as ExploreBuilding).cpsType.toUpperCase(),
        queryMaps: '',
        mapId: '',
        region: '',
      },
    });
    if (data.dataType === 'image') {
      try {
        dispatch({
          type: MAP_OVERLAY_CPS_IMAGE_UPDATED,
          payload: `data:image/jpeg;base64,${data.imageData.data.image}`,
        });

        if (building.cpsType === 'li' || building.cpsType === 'fi' || override === 'Immersal') {
          const mapIds: string[] =
            override === 'Immersal' ? maps.split(',') : building.getCpsMapIds();

          const region = building.cpsRegion;
          let endpoint = '';

          if (building.source === 'aws') {
            endpoint = region
              ? `${config.immersalCpsEndpoint}?region=${region}`
              : config.immersalCpsEndpoint;
          } else if (building.source === 'az') {
            endpoint = region
              ? `${config.immersalAzureEndpoint}?region=${region}`
              : config.immersalAzureEndpoint;
          }

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapIds.join(', '), region: region || 'none' },
          });
          const result = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({
              mapIds: mapIds.map(m => ({ id: parseInt(m, 10) })),
              token: 'e2d001de07076b631a10b7787a6f3a16bfb40520eeb8efea20509ca84991ea4b',
              ox: data.imageData.data.intrinsics.cx,
              oy: data.imageData.data.intrinsics.cy,
              fx: data.imageData.data.intrinsics.fx,
              fy: data.imageData.data.intrinsics.fy,
              jpeg: `data:image/jpeg;base64,${data.imageData.data.image}`,
            }),
          });

          dispatch({ type: CPS_LOADING_CHANGED, payload: false });
          const json = await result.json();

          const m = new THREE.Matrix4();
          m.set(
            json.r00,
            json.r01,
            json.r02,
            0,
            json.r10,
            json.r11,
            json.r12,
            0,
            json.r20,
            json.r21,
            json.r22,
            0,
            0,
            0,
            0,
            0
          );
          const q = new THREE.Quaternion();
          q.setFromRotationMatrix(m);

          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');

          if (
            !json.success ||
            ((building as ExploreBuilding).cpsType === 'li' &&
              !(building as ExploreBuilding).arPositioning.isCPSInCorrectLevel(
                {
                  x: json.px,
                  y: -1 * json.pz,
                  z: json.py,
                },
                json.map
              ))
          ) {
            dispatch({ type: CPS_ERROR_CHANGED, payload: true });
            console.log('error');
            return;
          }

          dispatch({ type: CPS_ERROR_CHANGED, payload: false });
          (building as ExploreBuilding).arPositioning.setCPS(
            (building as ExploreBuilding).cpsType === 'f' ||
              (building as ExploreBuilding).cpsType === 'fi'
              ? { x: json.px, y: json.pz, z: json.py }
              : { x: json.px, y: -1 * json.pz, z: json.py },
            (building as ExploreBuilding).cpsType === 'f' ||
              (building as ExploreBuilding).cpsType === 'fi'
              ? { x: q.x, y: q.y, z: q.z, w: q.w }
              : { x: q.x, y: q.z, z: q.y, w: q.w },
            (building as ExploreBuilding).cpsType === 'li' ? json.map : null
          );

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { mapId: json.map },
          });
        } else if ((building as ExploreBuilding).cpsType === 'f' || override === 'Fantasmo') {
          const formData = new FormData();
          const mapId = override === 'Fantasmo' ? maps : building.cpsMapId;
          formData.append('intrinsics', JSON.stringify(data.imageData.data.intrinsics));
          formData.append('capturedAt', '1583510835.267616');
          formData.append('uuid', 'E7E3060A-5184-4D12-96F3-D4F332C8A0CD');
          formData.append('mapId', mapId);
          formData.append('image', b64toBlob(data.imageData.data.image), 'image.jpg');
          cpsTimestamp = Date.now();
          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapId },
          });

          trackPromise(
            fetch('https://api.goodmaps.io/dev/cps', {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(responseData => {
                dispatch({ type: CPS_LOADING_CHANGED, payload: false });
                if (!responseData.pose) {
                  dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                  return;
                }

                dispatch({ type: CPS_ERROR_CHANGED, payload: false });
                const { x, y: z, z: y } = responseData.pose.position;

                (building as ExploreBuilding).arPositioning.setCPS(
                  { x, y, z },
                  responseData.pose.orientation
                );
              })
              .catch(error => {
                dispatch({ type: CPS_LOADING_CHANGED, payload: false });

                dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                alert('Could not load CPS position...');
                console.log(error);
              })
          );
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(startDataLoggerVideo(data.videoData.data, true));
    }
  };
};

const b64toBlob = (dataURI: string) => {
  const byteString = atob(dataURI);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

let updateInterval: any;

const startDataLoggerVideo = (data: VideoData[], useCPSImages) => dispatch => {
  const building = BuildingManagerHelper.getCurrentExploreEntity();
  stop();

  nextUpdateStep = 0;
  requestCounter = 0;
  (building as ExploreBuilding).arPositioning.reset();

  // const firstRequest = (data as any[]).findIndex(d => d.dataType === 'cpsRequested');
  // const firstReturn = (data as any[]).findIndex(d => d.dataType === 'cpsReturned');

  // if (firstRequest == null || firstReturn == null) {
  //   cpsTimestamp = Date.now();
  //   // (building as ExploreBuilding).arPositioning.prepareCPS(cpsTimestamp);
  // } else {
  //   cpsTimestamp = Date.now();
  //   // if (firstRequest > firstReturn)
  //   // (building as ExploreBuilding).arPositioning.prepareCPS(cpsTimestamp);
  // }

  dataNanoTimer.setTimeout(
    () => {
      dispatch(updateCPSVideo(data, 0, useCPSImages));
    },
    '',
    `${initialDelay}ms`
  );
};

const updateCPSVideo = (data: VideoData[], step, useCPSImages = true) => async dispatch => {
  const building = BuildingManagerHelper.getCurrentExploreEntity();
  const d = data[step];
  const { timestamp, dataType } = d;
  nextUpdateStep = step + 1;
  if (nextUpdateStep !== data.length) {
    const { timestamp: nextTimestamp } = data[nextUpdateStep];
    dataNanoTimer.setTimeout(
      () => {
        dispatch(updateCPSVideo(data, nextUpdateStep, useCPSImages));
      },
      '',
      `${nextTimestamp - timestamp}m`
    );
  }
  switch (dataType) {
    case 'ar': {
      const [x, z, y, o] = d.arData;
      (building as ExploreBuilding).arPositioning.updateDeviceARPosition(x, -y, o);
      dispatch({ type: AR_POSITION_UPDATED, payload: { x, y: -y, z } });
      const { speed } = building.getPosition();
      dispatch({ type: MAP_OVERLAY_EXTRA_INFO_UPDATED, payload: { speed: speed.toFixed(2) } });
      break;
    }
    case 'cpsImage': {
      try {
        const now = Date.now();
        (building as ExploreBuilding).arPositioning.prepareWorldPositionMarkerV2(now);

        dispatch({
          type: MAP_OVERLAY_CPS_IMAGE_UPDATED,
          payload: `data:image/jpeg;base64,${d.imageData.image}`,
        });

        if (
          (building as ExploreBuilding).cpsType === 'li' ||
          (building as ExploreBuilding).cpsType === 'fi'
        ) {
          const mapIds: string[] = building.getCpsMapIds();

          const region = building.cpsRegion;
          let endpoint = '';

          if (building.source === 'aws') {
            endpoint = region
              ? `${config.immersalCpsEndpoint}?region=${region}`
              : config.immersalCpsEndpoint;
          } else if (building.source === 'az') {
            endpoint = region
              ? `${config.immersalCpsEndpoint}?region=${region}`
              : config.immersalCpsEndpoint;
          }

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapIds.join(', '), region: region || 'none' },
          });
          const result = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({
              mapIds: mapIds.map(m => ({ id: parseInt(m, 10) })),
              token: 'e2d001de07076b631a10b7787a6f3a16bfb40520eeb8efea20509ca84991ea4b',
              ox: d.imageData.intrinsics.cx,
              oy: d.imageData.intrinsics.cy,
              fx: d.imageData.intrinsics.fx,
              fy: d.imageData.intrinsics.fy,
              jpeg: `data:image/jpeg;base64,${d.imageData.image}`,
            }),
          });

          dispatch({ type: CPS_LOADING_CHANGED, payload: false });
          const localizationResult = await result.json();

          const m = new THREE.Matrix4();
          m.set(
            localizationResult.r00,
            localizationResult.r01,
            localizationResult.r02,
            0,
            localizationResult.r10,
            localizationResult.r11,
            localizationResult.r12,
            0,
            localizationResult.r20,
            localizationResult.r21,
            localizationResult.r22,
            0,
            0,
            0,
            0,
            0
          );
          const q = new THREE.Quaternion();
          q.setFromRotationMatrix(m);

          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');
          const isFi = building.cpsType === 'fi';
          let isZValueOutOfRange = false;
          if (!isFi) {
            isZValueOutOfRange = !(building as ExploreBuilding).arPositioning.isCPSInCorrectLevel(
              {
                x: localizationResult.px,
                y: -1 * localizationResult.pz,
                z: localizationResult.py,
              },
              localizationResult.map
            );
          }

          if (!localizationResult.success || isZValueOutOfRange) {
            dispatch({ type: CPS_ERROR_CHANGED, payload: true });
            console.log('failed');
          } else {
            dispatch({ type: CPS_ERROR_CHANGED, payload: false });

            (building as ExploreBuilding).arPositioning.newConfirmCps(
              now,
              isFi
                ? { x: localizationResult.px, y: localizationResult.pz, z: localizationResult.py }
                : {
                    x: localizationResult.px,
                    y: -1 * localizationResult.pz,
                    z: localizationResult.py,
                  },
              isFi ? { x: q.x, y: q.y, z: q.z, w: q.w } : { x: q.x, y: q.z, z: q.y, w: q.w },
              isFi ? undefined : localizationResult.map
            );

            dispatch({
              type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
              payload: { mapId: localizationResult.map },
            });

            // successfulCPSCalls += 1;
            console.log('success');
          }
        } else if ((building as ExploreBuilding).cpsType === 'f') {
          const formData = new FormData();
          const mapId = building.cpsMapId;
          formData.append('intrinsics', JSON.stringify(d.imageData.intrinsics));
          formData.append('capturedAt', '1583510835.267616');
          formData.append('uuid', 'E7E3060A-5184-4D12-96F3-D4F332C8A0CD');
          formData.append('mapId', mapId);
          formData.append('image', b64toBlob(d.imageData.image), 'image.jpg');
          cpsTimestamp = Date.now();
          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapId },
          });

          await trackPromise(
            fetch('https://api.goodmaps.io/dev/cps', {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(responseData => {
                dispatch({ type: CPS_LOADING_CHANGED, payload: false });
                if (!responseData.pose) {
                  dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                  return;
                }

                dispatch({ type: CPS_ERROR_CHANGED, payload: false });
                const { x, y: z, z: y } = responseData.pose.position;

                (building as ExploreBuilding).arPositioning.setCPS(
                  { x, y, z },
                  responseData.pose.orientation
                );

                // successfulCPSCalls += 1;
                console.log('success');
              })
              .catch(error => {
                dispatch({ type: CPS_LOADING_CHANGED, payload: false });

                dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                alert('Could not load CPS position...');
                console.log(error);
              })
          );
        }
      } catch (e) {
        console.log(e);
      }
      break;
    }
    // case 'cpsRequested':
    //   if (useCPSImages) break;
    //   cpsTimestamp = Date.now();
    //   // (building as ExploreBuilding).arPositioning.prepareCPS(cpsTimestamp);
    //   break;
    // case 'cpsReturned': {
    //   if (useCPSImages) break;
    //   if (!d.pose) break;
    //   const { x, y: z, z: y } = d.pose.position;
    //   (building as ExploreBuilding).arPositioning.updateCPS(
    //     cpsTimestamp,
    //     { x, y, z },
    //     d.pose.orientation
    //   );
    //   // const { drift } = (building as ExploreBuilding).arPositioning.getStatus();
    //   // const { currentDrift, previousDrifts } = drift;
    //   break;
    // }
    default:
      break;
  }
};

export const runCPSTest = (
  dataset: DataLoggerDataset[],
  override = undefined,
  maps = undefined
) => async dispatch => {
  const building = BuildingManagerHelper.getCurrentExploreEntity();
  let successfulCPSCalls = 0;

  dispatch(stop());
  dispatch({ type: CPS_LOADING_CHANGED, payload: true });

  const testCPSimages = async (d: DataLoggerDataset[], i) => {
    if (i < 0) {
      const total = d.filter(x => x.dataType === 'image').length;
      console.log(`CPS Success Rate: ${(successfulCPSCalls / total) * 100}%`);
      // document.getElementById('cps-test-result').innerHTML = `CPS Success Rate: ${
      //   (successfulCPSCalls / total) * 100
      // }%`;

      dispatch({
        type: CPS_TEST_MESSAGE_UPDATE,
        payload: `CPS Success Rate: ${(successfulCPSCalls / total) * 100}%`,
      });
      // downloadImagePoses();
      return;
    }

    // document.getElementById('cps-test-result').innerHTML = '';
    dispatch({ type: CPS_TEST_MESSAGE_UPDATE, payload: `` });
    const { dataType } = d[i];
    if (dataType === 'image') {
      const data = d[i].imageData;
      console.log(`testing ${data.name}...`);
      // document.getElementById('cps-test-result').innerHTML = `testing ${data.name}...`;
      dispatch({ type: CPS_TEST_MESSAGE_UPDATE, payload: `testing ${data.name}...` });
      try {
        dispatch({
          type: MAP_OVERLAY_CPS_IMAGE_UPDATED,
          payload: `data:image/jpeg;base64,${data.data.image}`,
        });

        if (
          (building as ExploreBuilding).cpsType === 'li' ||
          (building as ExploreBuilding).cpsType === 'fi' ||
          override === 'Immersal'
        ) {
          const mapIds: string[] =
            override === 'Immersal' ? maps.split(',') : building.getCpsMapIds();

          const region = building.cpsRegion;
          let endpoint = '';

          if (building.source === 'aws') {
            endpoint = region
              ? `${config.immersalCpsEndpoint}?region=${region}`
              : config.immersalCpsEndpoint;
          } else if (building.source === 'az') {
            endpoint = region
              ? `${config.immersalAzureEndpoint}?region=${region}`
              : config.immersalAzureEndpoint;
          }

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapIds.join(', '), region: region || 'none' },
          });
          const result = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({
              mapIds: mapIds.map(m => ({ id: parseInt(m, 10) })),
              token: 'e2d001de07076b631a10b7787a6f3a16bfb40520eeb8efea20509ca84991ea4b',
              ox: data.data.intrinsics.cx,
              oy: data.data.intrinsics.cy,
              fx: data.data.intrinsics.fx,
              fy: data.data.intrinsics.fy,
              jpeg: `data:image/jpeg;base64,${data.data.image}`,
            }),
          });

          dispatch({ type: CPS_LOADING_CHANGED, payload: false });
          const json = await result.json();

          const m = new THREE.Matrix4();
          m.set(
            json.r00,
            json.r01,
            json.r02,
            0,
            json.r10,
            json.r11,
            json.r12,
            0,
            json.r20,
            json.r21,
            json.r22,
            0,
            0,
            0,
            0,
            0
          );
          const q = new THREE.Quaternion();
          q.setFromRotationMatrix(m);

          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');

          if (!json.success) {
            dispatch({ type: CPS_ERROR_CHANGED, payload: true });
            console.log('failed');
          } else {
            dispatch({ type: CPS_ERROR_CHANGED, payload: false });
            (building as ExploreBuilding).arPositioning.setCPS(
              (building as ExploreBuilding).cpsType === 'f' ||
                (building as ExploreBuilding).cpsType === 'fi'
                ? { x: json.px, y: json.pz, z: json.py }
                : { x: json.px, y: -1 * json.pz, z: json.py },
              (building as ExploreBuilding).cpsType === 'f' ||
                (building as ExploreBuilding).cpsType === 'fi'
                ? { x: q.x, y: q.y, z: q.z, w: q.w }
                : { x: q.x, y: q.z, z: q.y, w: q.w },
              (building as ExploreBuilding).cpsType === 'li' ? json.map : null
            );

            dispatch({
              type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
              payload: { mapId: json.map },
            });

            successfulCPSCalls += 1;
            console.log('success');
          }
        } else if ((building as ExploreBuilding).cpsType === 'f' || override === 'Fantasmo') {
          const formData = new FormData();
          const mapId = override === 'Fantasmo' ? maps : building.cpsMapId;
          formData.append('intrinsics', JSON.stringify(data.data.intrinsics));
          formData.append('capturedAt', '1583510835.267616');
          formData.append('uuid', 'E7E3060A-5184-4D12-96F3-D4F332C8A0CD');
          formData.append('mapId', mapId);
          formData.append('image', b64toBlob(data.data.image), 'image.jpg');
          cpsTimestamp = Date.now();
          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapId },
          });

          await trackPromise(
            fetch('https://api.goodmaps.io/dev/cps', {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(responseData => {
                dispatch({ type: CPS_LOADING_CHANGED, payload: false });
                if (!responseData.pose) {
                  dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                  return;
                }

                dispatch({ type: CPS_ERROR_CHANGED, payload: false });
                const { x, y: z, z: y } = responseData.pose.position;

                (building as ExploreBuilding).arPositioning.setCPS(
                  { x, y, z },
                  responseData.pose.orientation
                );

                successfulCPSCalls += 1;
                console.log('success');
              })
              .catch(error => {
                dispatch({ type: CPS_LOADING_CHANGED, payload: false });

                dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                alert('Could not load CPS position...');
                console.log(error);
              })
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
    testCPSimages(d, i - 1);
  };

  testCPSimages(dataset, dataset.length - 1);

  dispatch({ type: CPS_LOADING_CHANGED, payload: false });
};



// Analyzes the success of each data logger position, and the whole building. (Immersal only)
// For each position (id determined by the image name's first integer),
//    Attempt CPS on each orientation (id determined by the second integer in the image name).
//    Compute the x,y centroid in building coordinates and assume that as ground truth.
//    Eliminate outliers beyond specified threshold (in meters) and consider them as failed.
//    Display the percentage of successful orientations, the mean distance from centroid in meters,
//      standard deviation, and root mean squared error.
// Also display the success percentages, the mean distance, stddev, and RMSE for the whole dataset.
// If positionIds array is given, it will only check images for those positions, if empty it'll check all positions.
//
export const runCPSHealthAnalysis = (
  dataset: DataLoggerDataset[],
  positionIds = [],
  override = undefined,
  maps = undefined
) => async dispatch => {

  // ignore results beyond this distance (meters) from centroid
  const outlierThreshold = 3.0;

  // Results
  const results = new HealthCheckResults();
  
  const building = BuildingManagerHelper.getCurrentExploreEntity();
  dispatch(stop());
  dispatch({ type: CPS_LOADING_CHANGED, payload: true });

  const updateOutput = () => {
    dispatch({
      type: CPS_TEST_MESSAGE_UPDATE,
      payload: `${results.currentTest} ${results.unitResult} ${results.overallResult}`,
    });
  };

  // Recursively iterates data logger images.  i = next position in dataset to visit
  const analyzeCPSimages = async (d: DataLoggerDataset[], i) => {
    if (i < 0) {
      // Done iterating data logger images
      results.currentTest = '';
      results.unitResult = '';
      updateOutput();

      // Generate and download CSV results file
      console.log('Generating CSV file');
      const csvStr = results.generateCSV(outlierThreshold);
      downloadTextFile('cps-results.csv', csvStr);

      building.renderer.clearCrosshairs();
      return;
    }

    const { dataType } = d[i];
    if (dataType === 'image') {
      const data = d[i].imageData;
      console.log(`testing ${data.name}...`);

      results.currentTest = `testing ${data.name} `;
      updateOutput();

      try {
        dispatch({
          type: MAP_OVERLAY_CPS_IMAGE_UPDATED,
          payload: `data:image/jpeg;base64,${data.data.image}`,
        });

        // Parse position and orientation IDs from image name
        const [positionId, orientationId] = data.name.split('-').map(x => parseInt(x, 10));

        // Ensure IDs are valid and that the caller wants to check this position.
        // If not, move to next image.
        if (
          !Number.isInteger(positionId) ||
          !Number.isInteger(orientationId) ||
          (positionIds.length > 0 && !positionIds.includes(positionId))
        ) {
          analyzeCPSimages(d, i - 1);
          return;
        }

        // only immersal buildings
        // todo: why is it checking building type on every iteration?
        if (
          (building as ExploreBuilding).cpsType === 'li' ||
          (building as ExploreBuilding).cpsType === 'fi' ||
          override === 'Immersal'
        ) {
          const mapIds: string[] =
            override === 'Immersal' ? maps.split(',') : building.getCpsMapIds();

          const region = building.cpsRegion;
          let endpoint = '';

          if (building.source === 'aws') {
            endpoint = region
              ? `${config.immersalCpsEndpoint}?region=${region}`
              : config.immersalCpsEndpoint;
          } else if (building.source === 'az') {
            endpoint = region
              ? `${config.immersalAzureEndpoint}?region=${region}`
              : config.immersalAzureEndpoint;
          }

          dispatch({
            type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
            payload: { queryMaps: mapIds.join(', '), region: region || 'none' },
          });
          const result = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({
              mapIds: mapIds.map(m => ({ id: parseInt(m, 10) })),
              token: 'e2d001de07076b631a10b7787a6f3a16bfb40520eeb8efea20509ca84991ea4b',
              ox: data.data.intrinsics.cx,
              oy: data.data.intrinsics.cy,
              fx: data.data.intrinsics.fx,
              fy: data.data.intrinsics.fy,
              jpeg: `data:image/jpeg;base64,${data.data.image}`,
            }),
          });

          dispatch({ type: CPS_LOADING_CHANGED, payload: false });
          const json = await result.json();

          const m = new THREE.Matrix4();
          m.set(
            json.r00,
            json.r01,
            json.r02,
            0,
            json.r10,
            json.r11,
            json.r12,
            0,
            json.r20,
            json.r21,
            json.r22,
            0,
            0,
            0,
            0,
            0
          );
          const q = new THREE.Quaternion();
          q.setFromRotationMatrix(m);

          building.renderer.clearMarker('worldPositionMarker-circle');
          building.renderer.clearMarker('rawUserOrientationMarker-arrow');

          // Initilize collections for this position
          results.initPosition(positionId);

          if (!json.success) {
            dispatch({ type: CPS_ERROR_CHANGED, payload: true });
            console.log('failed');
            results.cpsLocations.get(positionId).set(orientationId, null);
          } else {
            dispatch({ type: CPS_ERROR_CHANGED, payload: false });

            const buildingPose = (building as ExploreBuilding).arPositioning.setCPS(
              (building as ExploreBuilding).cpsType === 'f' ||
                (building as ExploreBuilding).cpsType === 'fi'
                ? { x: json.px, y: json.pz, z: json.py }
                : { x: json.px, y: -1 * json.pz, z: json.py },
              (building as ExploreBuilding).cpsType === 'f' ||
                (building as ExploreBuilding).cpsType === 'fi'
                ? { x: q.x, y: q.y, z: q.z, w: q.w }
                : { x: q.x, y: q.z, z: q.y, w: q.w },
              (building as ExploreBuilding).cpsType === 'li' ? json.map : null
            );

            dispatch({
              type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
              payload: { mapId: json.map },
            });

            // Draw a circle on the map for the returned location
            building.renderer.renderCircleMarker(
              `${positionId}-${orientationId}`,
              new THREE.Vector3(buildingPose.position.x, buildingPose.position.y, 1.3),
              0x8888aa,
              0.24
            );

            // Store resulting location
            console.log('success');
            console.log(
              `${buildingPose.position.x.toFixed(2)}, ${buildingPose.position.y.toFixed(
                2
              )} on floor ${buildingPose.floor.level}`
            );
            results.cpsLocations.get(positionId).set(orientationId, buildingPose.position);
            results.cpsFloors.get(positionId).set(orientationId, buildingPose.floor);

            // Determine if on majority floor
            // todo: Add a more sophisticated check for correct floor, such as which one produces the most accurate centroid.
            const majorityFloor = results.findMajorityFloor(positionId);
            if (buildingPose.floor.level !== majorityFloor.level) {
              console.warn(
                `Warning: Multiple floors detected for position ${positionId}.` +
                  `\nCurrent: ${buildingPose.floor.level} Majority: ${majorityFloor.level}` +
                  '\nMinority floors will mot be included in centroid.'
              );
            }

            // (Re)compute centroid from all successful returns for this position
            computeCentroid(results, positionId, majorityFloor.level, outlierThreshold);

          
            // Draw a circle and crosshairs on the map for the centroid
            const centroid = results.meanCentroidMethod.centroids.get(positionId);
            building.renderer.renderCircleMarker(
              `centroid${positionId}`,
              new THREE.Vector3(centroid.x, centroid.y, 1.4),
              0x444477,
              0.18
            );
            building.renderer.renderCrosshairs(
              new THREE.Vector3(centroid.x, centroid.y, 1.4),
              0x996622
            );
          }

          // Compute metrics for this position and overall too
          computeMetrics(positionId, results, outlierThreshold, updateOutput);

        }
      } catch (e) {
        console.log(e);
      }
    }
    analyzeCPSimages(d, i - 1);
  };

  analyzeCPSimages(dataset, dataset.length - 1);

  dispatch({ type: CPS_LOADING_CHANGED, payload: false });
};

function downloadTextFile(fileName: string, text: string) {
  const blob = new Blob([text], { type: 'text/plain' });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
}

// eslint-disable-next-line no-unused-vars
export const stop = () => async dispatch => {
  try {
    clearInterval(updateInterval);
    dataNanoTimer.clearTimeout();
    dataNanoTimer.clearInterval();
  } catch (e) {
    console.log(e);
  }
};

/** *************************************************** */
// The following code is used for downloading tested cps images as jpg files
// and poses as json files. Currently being used with runCPSTest().
// Added file-saver and jszip libraries for this purpose.

// TODO: Move to helper file

// Global lists for tested CPS items (images/poses)
const imageList = [];
const poseList = [];

// Called when a response is returned from a CPS call to fill image and pose lists
export const compileImagePoses = async (intrinsics, responseData, image) => {
  const poseJSON = JSON.stringify({
    camera_index: 1,
    intrinsics,
    pose: responseData.pose,
    timestamp: cpsTimestamp,
    uuid: 'E7E3060A-5184-4D12-96F3-D4F332C8A0CD',
  });

  poseList.push({
    json: poseJSON,
    filename: `${cpsTimestamp}_E7E3060A-5184-4D12-96F3-D4F332C8A0CD.json`,
  });
  imageList.push({
    image,
    filename: `${cpsTimestamp}__E7E3060A-5184-4D12-96F3-D4F332C8A0CD.jpg`,
  });
};

// Called at the end of CPS image test to prompt user to download images and poses
export const downloadImagePoses = () => {
  const zip = JSZip();
  const imgs = imageList.map(img => {
    const imgObj = {
      src: img.image,
      file: img.filename,
    };
    return imgObj;
  });

  const poses = poseList.map(pose => {
    const poseElement = document.createElement('a');
    poseElement.text = pose.json;
    poseElement.id = pose.filename;
    return poseElement;
  });

  const imageFolder = zip.folder('Images');
  imgs.forEach(img => {
    imageFolder.file(img.file, b64toBlob(img.src));
  });

  const poseFolder = zip.folder('Poses');
  poses.forEach(pose => {
    poseFolder.file(pose.id, pose.text);
  });

  zip.generateAsync({ type: 'blob' }).then(content => {
    saveAs(content, 'ImagePose.zip');
  });
};
/** *************************************************** */
