import {
  Area,
  Point,
  Polygon,
  Position,
  IAmenityFeature,
  ICpsPointsFeature,
  IFixtureFeature,
  ILevelFeature,
  IMap,
  IOpeningFeature,
} from 'goodmaps-utils/index';
import { Vector2 } from 'three';
import { createPolygon } from 'goodmaps-sdk/dist/polygon';
import { Door, DoorAutomatic, DoorEntrance, IVenueFeature } from 'goodmaps-utils';
import ARPositioning from './positioning/ARPositioning';
import ExploreEntity from './ExploreEntity';
import { ExplorePosition } from './types';
import { POI } from './entities/POI';
import { calcProjection } from './helpers/Imdf.helper';
import { Floor } from './entities/Floor';
import { Room, RouteNetwork } from '.';

export default class ExploreIMDFBuilding extends ExploreEntity {
  imdfBuilding: IMap;

  arPositioning: ARPositioning;

  language?: string;

  private cpsPoints: {
    [mapId: string]: { st: number; ct: number; origin: Position; level_id: string };
  } = {};

  constructor(buildingData: IMap, language?: string) {
    super();
    this.language = language;
    this.name = buildingData.name;
    this.imdfBuilding = buildingData;
    // this.gmBuilding = null;
    // TODO: create an IMDF ARPostioning
    this.arPositioning = new ARPositioning(this);

    this.id = this.imdfBuilding.id;
    this.cpsType = 'li';

    // TODO: look to see if we need this try/catch
    try {
      const mapIds = this.imdfBuilding.features?.cps_points
        .map(tp => tp.properties.cps_map_id)
        .filter((mid, index, arr) => arr.indexOf(mid) === index);

      mapIds.forEach(mapId => {
        this.cpsPoints[mapId] = this.prepOriginTransform(
          this.imdfBuilding.features?.cps_points,
          this.imdfBuilding.features.venue,
          mapId
        );
      });
    } catch (e) {
      console.log(e);
    }

    const getPOI = (
      feature: IAmenityFeature | IFixtureFeature | IOpeningFeature,
      imdfBuilding: IMap,
      type: 'door' | 'poi' | 'testpoint'
    ): POI => {
      const { x, y } = this.calculateFeatureProjection(feature);

      const routePointID =
        type === 'door'
          ? `${feature.properties.display_point.coordinates[1]}${feature.properties.display_point.coordinates[0]}${feature.properties.level_id}`
          : `${feature.geometry.coordinates[1]}${feature.geometry.coordinates[0]}${feature.properties.level_id}`;

      const p: POI = {
        id: feature.id,
        routePointID,
        name: feature.properties?.name
          ? feature.properties?.name[language] || feature.properties?.name?.en
          : null,
        shortName: feature.properties?.alt_name
          ? feature.properties?.alt_name[language] || feature.properties.alt_name.en
          : null,
        type,
        position: new Vector2(x, y),
        level: 0, // TODO: handle level,
        startingPoint: false, /// TODO: Is there a starting point in IMDF?
        attachedRooms: [], // TODO: go though relations and push them
        extraInfo: [], // TODO: is there extra info in IMDF?
        poiType: feature.properties?.category ? feature.properties?.category : 'kiosk',
        missingTranslation: feature.properties?.name ? !feature.properties?.name[language] : false,
        shelf: (feature as IAmenityFeature).properties.shelf || false,
      };
      return p;
    };

    this.imdfBuilding.features.level.forEach(level => {
      let outline = [];
      outline = (level.geometry.coordinates as number[][][][])[0][0].map(point => {
        const proj = calcProjection(
          { type: 'point', coordinates: point },
          this.imdfBuilding.features.venue
        );
        return { x: proj.x, y: proj.y };
      });

      const floorPOIS: POI[] = [];
      this.imdfBuilding.features?.amenity.forEach(poi => {
        if (poi.properties.level_id !== level.id) return;
        const p = getPOI(poi, this.imdfBuilding, 'poi');
        this.entityTable[p.id] = p;
        this.pois.push(p);
        floorPOIS.push(p);
      });

      // TODO: Test points
      const floorDoors: POI[] = [];
      this.imdfBuilding.features.opening.forEach(door => {
        if (door.properties.level_id !== level.id) return;
        const p = getPOI(door, this.imdfBuilding, 'door');
        this.entityTable[p.id] = p;
        this.doors.push(p);
        floorDoors.push(p);
        if (p.name) {
          this.pois.push(p);
          floorPOIS.push(p);
        }
      });

      const floorRooms: Room[] = [];
      this.imdfBuilding.features.unit.forEach(room => {
        if (room.properties.level_id !== level.id) return;
        if (
          room.properties.category === 'stairs' ||
          room.properties.category === 'elevator' ||
          room.properties.category === 'escalator'
        )
          return;
        let r: Room = this.entityTable[room.id];

        if (!r) {
          r = new Room(
            room.id,
            room.properties.name ? room.properties.name[language] || room.properties.name?.en : '',
            room.properties.category,
            '',
            (room.geometry.coordinates as number[][][])[0].map((points: number[]) => {
              const { x, y } = calcProjection(points, this.imdfBuilding.features.venue);
              return {
                x,
                y,
                lat: points[1],
                lon: points[0],
                id: `${points[1]}${points[0]}${room.properties.level_id}`,
              };
            }),
            [],
            level.properties.ordinal
          );
          r.missingTranslation = r.name && !!room.properties.name[language];
          this.entityTable[room.id] = r;
          this.rooms.push(r);
        }

        try {
          this.imdfBuilding.features.relationship
            .filter(
              relationship =>
                !!relationship.properties.destination &&
                !!relationship.properties.origin &&
                (relationship.properties?.destination?.id === room.id ||
                  relationship.properties?.origin?.id === room.id)
            )
            .forEach(relationship => {
              const door: POI = this.entityTable[relationship.properties.intermediary[0].id]; // TODO: what if a relationship has more than one intermediary?
              if (door) {
                door.attachedRooms.push(r);
                r.doors.push(door);
              }
            });
        } catch (e) {
          console.log(e);
        }

        floorRooms.push(r);
      });
      // TODO: fixtures / areas (conversion fixtures = imdf fixtures areas = imdf sections)
      try {
        this.imdfBuilding.features.unit.forEach(room => {
          if (
            room.properties.category === 'stairs' ||
            room.properties.category === 'elevator' ||
            room.properties.category === 'escalator'
          ) {
            let r: Room = this.entityTable[room.id];
            if (!r) {
              r = new Room(
                room.id,
                room.properties.name
                  ? room.properties.name[language] || room.properties.name?.en
                  : '',
                'connection',
                room.properties.category,
                (room.geometry.coordinates as number[][][])[0].map((points: number[]) => {
                  const { x, y } = calcProjection(points, this.imdfBuilding.features.venue);
                  return {
                    x,
                    y,
                    lat: points[1],
                    lon: points[0],
                    id: `${points[1]}${points[0]}${room.properties.level_id}`,
                  };
                }),
                [],
                level.properties.ordinal
                // TODO: level list?
              );
              r.missingTranslation = r.name && !!room.properties.name[language];
              this.entityTable[room.id] = r;
              this.rooms.push(r);
            }
            this.imdfBuilding.features.relationship.forEach(relationship => {
              if (
                !!relationship.properties.destination &&
                !!relationship.properties.origin &&
                (relationship.properties?.destination?.id === room.id ||
                  relationship.properties?.origin?.id === room.id)
              ) {
                const door: POI = this.entityTable[relationship.properties.intermediary[0].id]; // TODO: what if a relationship has more than one intermediary?
                if (door) {
                  door.attachedRooms.push(r);
                  r.doors.push(door);
                }
              }
            });
            floorRooms.push(r);
          }
        });
      } catch (e) {
        console.log('bad unit');
        console.log(e);
      }
      // console.log('fixtures');
      this.imdfBuilding.features.fixture.forEach(room => {
        if (room.properties.level_id !== level.id) return;
        let r: Room = this.entityTable[room.id];

        if (!r) {
          r = new Room(
            room.id,
            room.properties.name ? room.properties.name[language] || room.properties.name?.en : '',
            'fixture',
            '',
            (room.geometry.coordinates as number[][][])[0].map((points: number[]) => {
              const { x, y } = calcProjection(points, this.imdfBuilding.features.venue);
              return {
                x,
                y,
                lat: points[1],
                lon: points[0],
                id: `${points[1]}${points[0]}${room.properties.level_id}`,
              };
            }),
            [],
            level.properties.ordinal
          );
          r.missingTranslation = r.name && !!room.properties.name[language];
          this.entityTable[room.id] = r;
          this.rooms.push(r);
        }

        try {
          this.imdfBuilding.features.relationship.forEach(relationship => {
            if (
              !!relationship.properties.destination &&
              !!relationship.properties.origin &&
              (relationship.properties?.destination?.id === room.id ||
                relationship.properties?.origin?.id === room.id)
            ) {
              const door: POI = this.entityTable[relationship.properties.intermediary[0].id]; // TODO: what if a relationship has more than one intermediary?
              if (door) {
                door.attachedRooms.push(r);
                r.doors.push(door);
              }
            }
          });

          const parent: Room = this.entityTable[room.properties.parents[0]];
          if (parent) {
            parent.addInnerFixture(r);
          }
        } catch (e) {
          // console.log('bad relationship');
        }

        floorRooms.push(r);
      });

      try {
        this.imdfBuilding.features.section.forEach(section => {
          if (section.properties.level_id !== level.id) {
            return;
          }
          let r: Room = this.entityTable[section.id];
          if (!r) {
            r = new Room(
              section.id,
              section.properties.name
                ? section.properties.name[language] || section.properties.name?.en
                : '',
              'area',
              '',
              (section.geometry.coordinates as number[][][])[0].map((points: number[]) => {
                const { x, y } = calcProjection(points, this.imdfBuilding.features.venue);
                return {
                  x,
                  y,
                  lat: points[1],
                  lon: points[0],
                  id: `${points[1]}${points[0]}${section.properties.level_id}`,
                };
              }),
              [],
              level.properties.ordinal
            );
            r.missingTranslation = r.name && !!section.properties.name[language];
            this.entityTable[section.id] = r;
            this.rooms.push(r);
          }

          this.imdfBuilding.features.relationship.forEach(relationship => {
            if (
              relationship.properties.destination.id === section.id ||
              relationship.properties.origin?.id === section.id
            ) {
              const door: POI = this.entityTable[relationship.properties.intermediary[0].id]; // TODO: what if a relationship has more than one intermediary?
              if (door) {
                door.attachedRooms.push(r);
                r.doors.push(door);
              }
            }
          });

          const parent: Room = this.entityTable[section.properties.parents[0]];
          if (parent) {
            parent.addInnerArea(r);
            r.addParent(parent);
          }
          floorRooms.push(r);
        });
      } catch (e) {
        console.log('bad section');
      }

      const floor: Floor = {
        level: level.properties.ordinal,
        name: level.properties.name
          ? level.properties.name[language] || level.properties.name?.en
          : '',
        pois: floorPOIS,
        doors: floorDoors,
        rooms: floorRooms,
        testPoints: [], // TODO: Test point
        outline: outline.map(point => new Vector2(point.x, point.y)),
        missingTranslation: level.properties.name && !!level.properties.name[language],
      };
      this.floors.push(floor);
    });

    // TODO: finish route
    const levels: { [id: string]: ILevelFeature } = {};
    this.imdfBuilding.features.level.forEach(l => {
      levels[l.id] = l;
    });
    const routes: {
      id: string;
      level: number;
      routeType: number;
      connectionType: number;
      nodeConnectionType: number;
      oneWay: boolean;
      x: number;
      y: number;
      weight: number;
    }[][] = this.imdfBuilding.features?.route?.map(route => {
      const routePointsArray = [];
      const nodes = (route.geometry.coordinates as number[][]).map((points: number[]) => {
        const { x, y } = calcProjection(points, this.imdfBuilding.features.venue);
        return {
          x,
          y,
          lat: points[1],
          lon: points[0],
          id: `${points[1]}${points[0]}${route.properties.level_id}`,
        };
      });

      let level = 0;

      try {
        level = levels[route.properties.level_id].properties.ordinal;
      } catch (e) {
        console.log(e);
        console.log(route.properties.level_id);
        console.log(route.id);
      }

      nodes?.forEach((node, index) => {
        routePointsArray.push({
          id: `${node.lat}${node.lon}${route.properties.level_id}`,
          routeType: 1,
          connectionType: null,
          oneWay: route.properties.restriction === 'oneway',
          nodeConnectionType: null,
          level,
          x: node.x,
          y: node.y,
          weight: 1,
        });
      });
      return routePointsArray;
    });

    const doorsTable: { [routePointID: string]: POI } = {};
    this.doors.forEach(d => {
      doorsTable[d.routePointID] = d;
    });
    this.routeNetwork = new RouteNetwork(this, routes || [], doorsTable);

    this.cpsMapId = this.imdfBuilding?.cpsMapId || null;
    // compare room points to route network and add matching ones
    this.rooms.forEach(room => {
      room.pointIds.forEach(id => {
        if (this.routeNetwork.getRoutePoint(id)) {
          room.routePoints.push(this.routeNetwork.getRoutePoint(id));
        }
      });
    });
  }

  getPosition(): ExplorePosition {
    return this.arPositioning.getPosition();
  }

  calculateFeatureProjection(
    feature: IAmenityFeature | IFixtureFeature | IOpeningFeature
  ): Position {
    return calcProjection(
      feature.properties.display_point || feature.geometry,
      this.imdfBuilding.features.venue
    );
  }

  private prepOriginTransform(
    testPoints: ICpsPointsFeature[],
    box: IVenueFeature,
    mapId?: string
  ): { st: number; ct: number; origin: Position; level_id: string } {
    try {
      let originPoint: Position;
      let fromPoint: Position;
      let toPoint: Position;
      let level_id: string = '';

      testPoints.forEach(node => {
        if (
          node.properties.x_pointcloud !== undefined &&
          node.properties.y_pointcloud !== undefined &&
          node.properties.x_pointcloud === 0 &&
          node.properties.y_pointcloud === 0 &&
          mapId &&
          node.properties.cps_map_id === mapId
        ) {
          // origin point found.
          level_id = node.properties.level_id;
          originPoint = calcProjection(node.geometry, box);
        }
        if (
          node.properties.x_pointcloud !== undefined &&
          node.properties.y_pointcloud !== undefined &&
          node.properties.x_pointcloud !== 0 &&
          node.properties.y_pointcloud !== 0 &&
          mapId &&
          node.properties.cps_map_id === mapId
        ) {
          // rotationpoint found
          fromPoint = { x: node.properties.x_pointcloud, y: node.properties.y_pointcloud };
          toPoint = calcProjection(node.geometry, box);
        }
      });

      toPoint = { x: toPoint.x - originPoint.x, y: toPoint.y - originPoint.y };

      const sTop = (fromPoint.y * toPoint.x) / fromPoint.x - toPoint.y;
      const sBot = fromPoint.x + (fromPoint.y * fromPoint.y) / fromPoint.x;
      const sinT = sTop / sBot;
      const cosT = (toPoint.x - fromPoint.y * sinT) / fromPoint.x;

      return {
        st: sinT,
        ct: cosT,
        origin: originPoint,
        level_id,
      };
    } catch (e) {
      console.log(e);
    }
  }

  getOriginTransform(position: Position /* cps coords */, translate?: boolean, mapId?: string) {
    try {
      const { origin, st, ct, level_id } = this.cpsPoints[mapId];
      const tx = position.x * ct + position.y * st;
      const ty = -1.0 * position.x * st + position.y * ct;

      let result: Position = {} as Position;

      if (translate) {
        result = {
          x: tx + origin.x,
          y: ty + origin.y,
        };
      } else {
        result = {
          x: tx,
          y: ty,
        };
      }

      const floorFeature = this.imdfBuilding.features.level.find(level => level.id === level_id);
      result.level = floorFeature
        ? floorFeature.properties.ordinal
        : this.findLevelReverse(position, result);
      return result;
    } catch (e) {
      console.log('Couldnt origin transform, probably arent any CPS MapIds');
      console.log(e);
      return null;
    }
  }

  private findLevelReverse(p: Position, xy: Position): number {
    this.imdfBuilding.features.level.sort((a, b) => a.properties.ordinal - b.properties.ordinal);
    const foundLevel = this.imdfBuilding.features.level.reduce(
      (acc: ILevelFeature | undefined, level, index) => {
        if (level.properties.minZ !== undefined && index > 0) {
          if (level.properties.minZ < p.z!) {
            return acc;
          }
          if (acc?.properties?.minZ === undefined || level.properties.minZ < acc.properties.minZ) {
            if (this.insideLevel(level, xy)) {
              return level;
            }
            return acc;
          }
        }
        return acc;
      },
      this.imdfBuilding.features.level[0]
    );
    return foundLevel.properties.ordinal;
  }

  private insideLevel(level: ILevelFeature, xy: Position): boolean {
    const polygon: Polygon = createPolygon();
    const floor = this.floors.find(f => f.level === level.properties.ordinal);

    floor.outline.forEach(point => {
      polygon.addPoint(point);
    });
    polygon.preCalc();

    const testPoint: Point = {
      x: xy.x,
      y: xy.y,
    };

    return polygon.pointInPolygon(testPoint);
  }

  reverseOriginTransform(position: Position) {
    return Error('NYI!!');
  }

  insideOf(position: { lat: number; lon: number }, range: number): boolean {
    const polygon: Polygon = createPolygon();
    (this.imdfBuilding.features.venue.geometry.coordinates as number[][][])[0].forEach(point => {
      polygon.addPoint({ x: point[1], y: point[0] });
    });
    polygon.preCalc();
    return (
      polygon.pointInPolygon({ x: position.lat, y: position.lon }) ||
      polygon.distanceFromPolygon({ x: position.lat, y: position.lon }) * 111.32 * 1000 < range
    );
  }

  getCpsMapIds(): string[] {
    return Object.keys(this.cpsPoints);
  }

  getCpsPoints(): {
    [mapId: string]: {
      st: number;
      ct: number;
      origin: Position;
      level_id: string;
    };
  } {
    return this.cpsPoints;
  }

  getDoors(level?: number): Door[] {
    const doors: Door[] = this.imdfBuilding.features.opening.map(opening => {
      const { x, y } = this.calculateFeatureProjection(opening);
      const door: Door = {
        doorType: 2,
        entrance: opening.properties.entrance,
        automatic: opening.properties.door?.automatic ? DoorAutomatic.Motion : DoorAutomatic.No,
        oneWay: false,
        duplicates: [],
        id: opening.id,
        x,
        y,
        lat: opening.properties.display_point.coordinates[1] as number,
        lon: opening.properties.display_point.coordinates[0] as number,
        level: this.imdfBuilding.features.level.find(
          level => level.id === opening.properties.level_id
        ).properties.ordinal,
        name: opening.properties?.name
          ? opening.properties?.name[this.language] || opening.properties?.name?.en
          : null,
      };
      return door;
    });

    if (level) {
      doors.filter(door => door.level === level);
    }

    return doors;
  }
}
