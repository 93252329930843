import * as THREE from 'three';
import { Floor } from '../_sdks/cavu-sdk/src/entities/Floor';
import { GroundTruthMethodState } from './GroundTruthMethodState';

type int = number;

export default class HealthCheckResults {

  // CPS results by position and orientation
  readonly cpsLocations = new Map<int, Map<int, THREE.Vector2>>(); 
  
  // Building floor, by position and orientation
  readonly cpsFloors = new Map<int, Map<int, Floor>>(); 
  
  // Orientations on the wrong floor, by position
  readonly numWrongFloor = new Map<int, int>(); 
  
  // Number of images sent to CPS system
  overallNumAttempts: int = 0;
  
  // Number of images that received a location from CPS
  overallNumSuccesses: int = 0;
  
  // Total number of CPS locations returned on "wrong" floor
  overallNumWrongFloor: int = 0;

  // Percent of images that received a location from CPS
  overallSuccessRate: number = 0.0;

  // Text displayed in status area of webpage
  currentTest: string = '';
  
  // Text displayed in status area of webpage
  unitResult: string = '';
  
  // Text displayed in status area of webpage
  overallResult: string = '';

  /**
   * Resulting metrics from various methods of estimating ground truth
   */
  readonly meanCentroidMethod = new GroundTruthMethodState();

  readonly meanCentroidExcludingOutliersMethod = new GroundTruthMethodState();


  /**
   * Initializes collections for a given position
   */
  initPosition(positionId: int) {
    if (!this.cpsLocations.has(positionId)) {
      this.cpsLocations.set(positionId, new Map<int, THREE.Vector2>());
    }
    if (!this.cpsFloors.has(positionId)) {
      this.cpsFloors.set(positionId, new Map<int, Floor>());
    }
    if (!this.meanCentroidMethod.distances.has(positionId)) {
      this.meanCentroidMethod.distances.set(positionId, new Map<int, number>());
    }
    if (!this.meanCentroidExcludingOutliersMethod.distances.has(positionId)) {
      this.meanCentroidExcludingOutliersMethod.distances.set(positionId, new Map<int, number>());
    }
  }

  /**
   * Returns the floor with the most occurrences in the results for the given position
   */
  findMajorityFloor( posID: int ): Floor {
    const counts = new Map<int, int>();
    let maxCount = 0;
    let maxFloor: Floor;
    this.cpsFloors.get(posID).forEach(floor => {
      const count = counts.has(floor.level) ? counts.get(floor.level) + 1 : 1;
      if (count > maxCount) {
        maxCount = count;
        maxFloor = floor;
      }
      counts.set(floor.level, count);
    });
    return maxFloor;
  }

  /**
   * Checks results against each other for internal consistency.  A faliure could
   * indicate a bug or edge case that needs to be handled differently.
   */
  checkConsistency(): boolean {
    if (this.overallNumSuccesses > this.overallNumAttempts ||
      this.overallNumSuccesses < 0) {
        console.error("Internal consistency of results failed");
        return false;
      }

    if (!this.meanCentroidMethod.checkConsistency()) {
      return false;
    }
    if (!this.meanCentroidExcludingOutliersMethod.checkConsistency()) {
      return false;
    }

    return true;
  }

  /**
   * Returns a string in CSV file format with the results
   */
  generateCSV( outlierThreshold: number ): string {
    
    let csvStr = 'Method,PositionID,#Orientations,#Successes,%Success,#InCentroid,CentroidX,CentroidY,Mean,StdDev,RMSE,OutlierThreshold,#CloseEnough,#WrongFloor,#FalsePositives,%Correct\n';
    csvStr += this.generateCSVForMethod( outlierThreshold, 'MeanCentroid', this.meanCentroidMethod );
    csvStr += this.generateCSVForMethod( outlierThreshold, 'MeanCentroidExcludingOutliers', this.meanCentroidExcludingOutliersMethod );
    return csvStr;
  }

  generateCSVForMethod( outlierThreshold: number, methodLabel: string, result: GroundTruthMethodState ): string {
    
    let csvStr = `${methodLabel},ALL,${this.overallNumAttempts},${this.overallNumSuccesses},${this.overallSuccessRate.toFixed(
      2
    )},${result.getOverallNumIncludedInCentroids()},,,${result.overallMeanDistance.toFixed(2)},` +
    `${result.overallStdDev.toFixed(2)},${result.overallRmse.toFixed(2)},${outlierThreshold.toFixed(
      2
    )},${result.overallNumCloseEnough},` +
    `${this.overallNumWrongFloor},${
      this.overallNumSuccesses - result.overallNumCloseEnough
    },${result.overallCorrectRate.toFixed(2)}\n`;
    
    this.cpsLocations.forEach((locationMap, posID) => {
      // Count up number of successes at each position
      let numSuccesses = 0;
      locationMap.forEach(location => (numSuccesses += location != null ? 1 : 0));

      const successRate = (100.0 * numSuccesses) / locationMap.size;
      const closeEnough = result.numCloseEnough.get(posID);
      const correctRate = (100.0 * closeEnough) / locationMap.size;
      const centroid = result.centroids.get(posID);
      csvStr +=
        `${methodLabel},${posID},${locationMap.size},${numSuccesses},${successRate.toFixed(2)},` +
        `${result.numLocationsInCentroid.get(posID)},` +
        `${centroid === undefined ? '' : centroid.x.toFixed(2)},` +
        `${centroid === undefined ? '' : centroid.y.toFixed(2)},`;

        if (result.meanDistances.has(posID)) {
          csvStr += result.meanDistances.get(posID).toFixed(2);
        }
        csvStr += ',';
        if (result.stdDevDistances.has(posID)) {
          csvStr += result.stdDevDistances.get(posID).toFixed(2);
        }
        csvStr += ',';
        if (result.rmseDistances.has(posID)) {
          csvStr += result.rmseDistances.get(posID)[2].toFixed(2);
        }
        csvStr += ',';

        csvStr += `${outlierThreshold.toFixed(2)},${result.numCloseEnough.get(posID)},${this.numWrongFloor.get(
          posID
        )},${numSuccesses - closeEnough},` +
        `${correctRate.toFixed(2)}\n`;
    })
    return csvStr;
  }

}