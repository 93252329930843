import * as THREE from 'three';
import { GroundTruthMethodState } from './GroundTruthMethodState';
import HealthCheckResults from './HealthCheckResults';

/**
 * Computes centroid using all methods
 * (mean centroid, with and without outliers)
 */
export const computeCentroid = (
  results: HealthCheckResults,
  positionId: number,
  majorityFloorLevel: number,
  outlierThreshold: number ) => {

    const sumLocations = new THREE.Vector2(0, 0);
    let successes = 0;
    let wrongFloor = 0;
    results.cpsLocations.get(positionId).forEach((cpsLocation, orientationKey) => {
      if (cpsLocation != null) {
        successes += 1;

        // If this floor is not the majority floor, then don't include it in the centroid
        if (results.cpsFloors.get(positionId).get(orientationKey).level === majorityFloorLevel) {
          sumLocations.add(cpsLocation);
        } else {
          wrongFloor += 1;
        }
        // console.log(`${positionId}-${orientationKey}: ${cpsLocation.x.toFixed(2)}, ${cpsLocation.y.toFixed(2)}`);
      } else {
        // console.log(`${positionId}-${orientationKey}: No response`);
      }
    });

    // Method 1: Centroid by simple average of all locations
    const numUsed = successes - wrongFloor;
    const centroid = sumLocations.divideScalar(numUsed);
    results.meanCentroidMethod.centroids.set(positionId, centroid);
    results.meanCentroidMethod.numLocationsInCentroid.set(positionId, numUsed);
    console.log(
      `Pos ${positionId} Centroid: ${centroid.x.toFixed(2)}, ${centroid.y.toFixed(2)}`
    );

    // Method 2: Recompute with simple average (without outliers from original centroid).
    // The difference from method 1 can be observed by the number of outliers from method 1 are
    // that many less used in the centroid for method 2.  Method 2 may then have a lower mean
    // distance, fewer outliers, and a higher correct rate.
    const sumLocationsNoOutliers = new THREE.Vector2(0, 0);
    let outliers = 0;
    results.cpsLocations.get(positionId).forEach((cpsLocation, orientationKey) => {
      if (cpsLocation != null) {
        // If this floor is not the majority floor, and/or this location is beyond the outlier threshold away
        // from the original centroid, then don't include it in the new centroid
        const distance = Math.abs(cpsLocation.distanceTo(centroid));
        if (results.cpsFloors.get(positionId).get(orientationKey).level === majorityFloorLevel
          && distance < outlierThreshold) {
          sumLocationsNoOutliers.add(cpsLocation);
        } else {
          outliers += 1;
        }
      }
    });
    const numCorrect = successes - outliers;
    const centroidNoOutliers = sumLocationsNoOutliers.divideScalar(numCorrect);
    if (numCorrect > 0) {  // valid centroid?
      results.meanCentroidExcludingOutliersMethod.centroids.set(positionId, centroidNoOutliers);
    } else {
      results.meanCentroidExcludingOutliersMethod.centroids.delete(positionId);
    }
    results.meanCentroidExcludingOutliersMethod.numLocationsInCentroid.set(positionId, numCorrect);
    console.log(
      `Pos ${positionId} Centroid-no-outliers: ${centroidNoOutliers.x.toFixed(2)}, ${centroidNoOutliers.y.toFixed(2)}`
    );
    
};


/**
 * Computes metrics for the given positionId and Overall
 */
export const computeMetrics = (
  positionId: number,
  results: HealthCheckResults,
  outlierThreshold: number,
  updateOutput: () => void) => {

  // Compute metrics based each method of ground truth estimation
  computeMetricsForMethod(results.meanCentroidMethod, positionId, results, outlierThreshold, updateOutput);
  computeMetricsForMethod(results.meanCentroidExcludingOutliersMethod, positionId, results, outlierThreshold, updateOutput);

};


/**
 * Computes metrics for one ground truth estimation method.
 * For one position ID and alao overall.
 */
export const computeMetricsForMethod = (
  method: GroundTruthMethodState,
  positionId: number,
  results: HealthCheckResults,
  outlierThreshold: number,
  updateOutput: () => void) => {

  // Compute distances from centroids, for ALL positions
  results.overallNumAttempts = 0;
  results.overallNumSuccesses = 0;
  results.overallNumWrongFloor = 0;
  method.overallNumCloseEnough = 0;
  
  // Intermediate overall values
  let overallDistancesSum = 0;
  let overallSquaredDeviationsSum = 0;
  let overallXSquareDiffSum = 0;
  let overallYSquareDiffSum = 0;

  // For each position
  results.cpsLocations.forEach((locationMap, iPos) => {
    // Intermediate values, only for iPos
    let successes = 0;
    let distancesSum = 0;
    let squaredDeviationsSum = 0;
    let closeEnough = 0;
    let wrongFloor = 0;
    let xSquareDiffSum = 0;
    let ySquareDiffSum = 0;

    const majorityFloor = results.findMajorityFloor(iPos);

    // For each attempted CPS image location at iPos position
    locationMap.forEach((cpsLocation, orientationKey) => {
      results.overallNumAttempts += 1;
      let distance = null;
      if (cpsLocation != null) {
        successes += 1;
        results.overallNumSuccesses += 1;

        // Check for correct (majority) floor
        const correctFloor = majorityFloor.level === results.cpsFloors.get(iPos).get(orientationKey).level;
        if (!correctFloor) {
          // console.warn(`Wrong floor, on ${cpsFloors.get(iPos).get(orientationKey).level} majority ${majorityFloor.level}`);
          wrongFloor += 1;
          results.overallNumWrongFloor += 1;
        }

        // Did method produce a valid centroid for this position?
        if (method.centroids.has(iPos)) {

          const centroid = method.centroids.get(iPos);
          distance = Math.abs(cpsLocation.distanceTo(centroid));

          // "close enough" means that the returned location lies less than the threshold distance away from the centroid,
          // and it lies on the correct floor.
          if (distance < outlierThreshold && correctFloor) {
            closeEnough += 1;
            method.overallNumCloseEnough += 1;
          }

          distancesSum += distance;
          overallDistancesSum += distance;
          const xSquareDiff = (method.centroids.get(iPos).x - cpsLocation.x) ** 2;
          const ySquareDiff = (method.centroids.get(iPos).y - cpsLocation.y) ** 2;
          xSquareDiffSum += xSquareDiff;
          ySquareDiffSum += ySquareDiff;
          overallXSquareDiffSum += xSquareDiff;
          overallYSquareDiffSum += ySquareDiff;
        }
        method.distances.get(iPos).set(orientationKey, distance);
        // console.log(`distance: ${distance != null ? distance.toFixed(2) : 'null'}`);
      }
      method.numCloseEnough.set(iPos, closeEnough);
      results.numWrongFloor.set(iPos, wrongFloor);
    });

    // Compute success rate and metrics about distance from centroid (current position only)
    // Success rate, Mean, StdDev, and RMSE do include outliers and wrong floors.
    // Correct rate does not include outliers nor wrong floors.
    if (positionId === iPos) {
      const successRate = (100.0 * successes) / results.cpsLocations.get(iPos).size;
      console.log(`Pos ${iPos} Success rate: ${successRate.toFixed(2)}%`);
      const correctRate = (100.0 * closeEnough) / results.cpsLocations.get(iPos).size;
      console.log(`Pos ${iPos} Correct rate: ${correctRate.toFixed(2)}%`);
      const meanDistance = distancesSum / successes;
      console.log(`Pos ${iPos} Avg distance: ${meanDistance.toFixed(2)} m`);
      method.meanDistances.set(iPos, meanDistance);

      method.distances.get(iPos).forEach(dist => {
        squaredDeviationsSum += (meanDistance - dist) ** 2;
      });
      const stddev = Math.sqrt(squaredDeviationsSum / successes);
      method.stdDevDistances.set(iPos, stddev);
      console.log(`Pos ${iPos} Std Deviation: ${stddev.toFixed(2)} m`);

      const rmseX = Math.sqrt(xSquareDiffSum / successes);
      const rmseY = Math.sqrt(ySquareDiffSum / successes);
      const rmseCombined = Math.sqrt(rmseX ** 2 + rmseY ** 2);
      console.log(
        `Pos ${iPos} rmseX ${rmseX.toFixed(2)} rmseY ${rmseY.toFixed(
          2
        )} rmse ${rmseCombined.toFixed(2)}`
      );
      method.rmseDistances.set(iPos, [rmseX, rmseY, rmseCombined]);

      results.unitResult = `Pos ${iPos}: ${successRate.toFixed(2)}% / ${correctRate.toFixed(
        2
      )}% / ${meanDistance.toFixed(2)} m`;
      updateOutput();
    }
  });

  // Compute overall success rate and metrics about distances from centroids
  // Success rate, Mean, StdDev, and RMSE do include outliers and wrong floors.
  // Correct rate does not include outliers nor wrong floors.
  results.overallSuccessRate = (100.0 * results.overallNumSuccesses) / results.overallNumAttempts;
  console.log(`Overall Success rate: ${results.overallSuccessRate.toFixed(2)}%`);
  method.overallCorrectRate = (100.0 * method.overallNumCloseEnough) / results.overallNumAttempts;
  console.log(`Overall Correct rate: ${method.overallCorrectRate.toFixed(2)}%`);
  method.overallMeanDistance = overallDistancesSum / results.overallNumSuccesses;
  console.log(`Overall Avg distance: ${method.overallMeanDistance.toFixed(2)} m`);

  method.distances.forEach(innerMap => {
    innerMap.forEach(dist => {
      overallSquaredDeviationsSum += (method.overallMeanDistance - dist) ** 2;
    });
  });
  method.overallStdDev = Math.sqrt(overallSquaredDeviationsSum / results.overallNumSuccesses);
  console.log(`Overall Std Deviation: ${method.overallStdDev.toFixed(2)} m`);

  method.overallRmseX = Math.sqrt(overallXSquareDiffSum / results.overallNumSuccesses);
  method.overallRmseY = Math.sqrt(overallYSquareDiffSum / results.overallNumSuccesses);
  method.overallRmse = Math.sqrt(method.overallRmseX ** 2 + method.overallRmseY ** 2);
  console.log(
    `Overall rmseX ${method.overallRmseX.toFixed(2)} rmseY ${method.overallRmseY.toFixed(
      2
    )} rmse ${method.overallRmse.toFixed(2)}`
  );

  results.overallResult = `Overall: ${results.overallSuccessRate.toFixed(
    2
  )}% / ${method.overallCorrectRate.toFixed(2)}% / ${method.overallMeanDistance.toFixed(2)} meters`;
  updateOutput();

};